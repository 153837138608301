import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-classes"></a><h2>Psionic Classes
    </h2>
    <a id="psion"></a><h3>PSION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/33175.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><a href="http://www.wizards.com/dnd/images/xph_gallery/33173.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Any.
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <h6>Class Skills</h6>
The psion&#8217;s class skills (and the key ability for each skill) are <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a>* (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a> (all skills, taken
individually)* (Int), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a> (Wis), and <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>*
(Int). In
addition, a psion gains access to additional class skills based on his
discipline:
    <p><span style={{
        "fontStyle": "italic"
      }}>Seer (Clairsentience):</span>
      <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
(Wis), and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> (Wis).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Shaper (Metacreativity):</span>
      <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> (Cha), and <a href="psionicSkills.html#use-psionic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Psionic Device</a>* (Cha).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Kineticist (Psychokinesis):</span>
      <a href="psionicSkills.html#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a>* (Wis), <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable
Device</a> (Dex), and <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> (Cha).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Egoist (Psychometabolism):</span>
      <a href="psionicSkills.html#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a>* (Wis), <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>
(Dex) and <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a> (Wis).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Nomad (Psychoportation):</span>
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> (Wis), and <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Telepath (Telepathy):</span>
      <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), and <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> (Wis).</p>
    <p>*New skill or expanded use of existing skill.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at 1st Level:</span>
(2 + Int modifier) x4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier.</p>
    <a id="table-the-psion"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Psion</span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="1" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="1" style={{
            "width": "34%"
          }}>Special</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Power Points/Day</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span></td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Maximum Power Level Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Bonus feat, discipline
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>13
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>46
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>58
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>72
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>88
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>21
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>106
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>22
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>126
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>24
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>147
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7th
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+7/+2</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>170
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>27
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7th
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
          <td>Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>195
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>28
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8th
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>221
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8th
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>250
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>31
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>280
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>33
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>311
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>34
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>343
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>36
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psion-class-features"></a><h6>Class Features</h6>
All the following are class features of the psion.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Psions are proficient with the club, dagger, heavy crossbow, light
crossbow, quarterstaff, and shortspear. They are not proficient with
any type of armor or shield. Armor does not, however, interfere with
the manifestation of powers.</p>
    <a id="psion-power-points-per-day"></a><p><span style={{
        "fontWeight": "bold"
      }}>Power Points/Day:</span> A
psion&#8217;s ability to manifest powers is limited by the power points he
has available. His base daily allotment of power points is given on
Table: The Psion. In addition, he receives bonus power points per day
if he has a high Intelligence score (see Table: Ability Modifiers and
Bonus Power Points). His race may also provide bonus power points per
day, as may certain feats and items.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Discipline:</span> Every
psion must decide at 1st level which psionic discipline he will
specialize in. Choosing a discipline provides a psion with access to
the class skills associated with that discipline (see above), as well
as the <a href="psionicDisciplineList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>powers restricted to that discipline</a>.
However, choosing a
discipline also means that the psion cannot learn powers that are
restricted to other disciplines. He can&#8217;t even use such powers by
employing psionic items.</p>
    <a id="psion-powers-known"></a><p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> A psion
begins play knowing three psion powers of your choice. Each time he
achieves a new level, he unlocks the knowledge of new powers.
Choose the powers known from the <a href="PsionicPsionList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>psion power list</a>, or from the
list of <a href="psionicDisciplineList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>powers of your chosen discipline</a>.
You cannot choose powers from
restricted discipline lists other than your own discipline list. You can
choose powers from disciplines other than your own if they are not on a
restricted discipline list. (<span style={{
        "fontStyle": "italic"
      }}>Exception:</span>
The feats <a href="psionicFeats.html#expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Expanded
Knowledge</a> and <a href="epicFeats.html#epic-expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Epic Expanded Knowledge</a> do allow
a psion to learn powers
from the lists of other disciplines or even other classes.) A psion can
manifest any power that has a power point cost equal to or lower than
his manifester level.
The number of times a psion can manifest powers in a day is limited
only by his daily power points.
A psion simply knows his powers; they are ingrained in his mind. He
does not need to prepare them (in the way that some spellcasters
prepare their spells), though he must get a good night&#8217;s sleep each day
to regain all his spent power points.
The Difficulty Class for saving throws against psion powers is 10 + the
power&#8217;s level + the psion&#8217;s Intelligence modifier. Maximum Power Level
Known: A psion begins play with the ability to learn 1st-level powers.
As he attains higher levels, a psion may gain the ability to master
more complex powers.
To learn or manifest a power, a psion must have an Intelligence score
of at least 10 + the power&#8217;s level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> A psion
gains a bonus feat at 1st level, 5th level, 10th level, 15th level, and
20th level. This feat must be a psionic feat, a metapsionic feat, or a
psionic item creation feat.
These bonus feats are in addition to the feats that a character of any
class gains every three levels. A psion is not limited to psionic
feats, metapsionic feats, and psionic item creation feats when choosing
these other feats.
    </p>
    <a id="psionic-disciplines"></a><h5>PSIONIC DISCIPLINES</h5>
A discipline is one of six groupings of powers, each defined
by a common theme. The six disciplines are clairsentience,
metacreativity, psychokinesis, psychometabolism, psychoportation, and
telepathy.
    <a id="clairsentience"></a><p><span style={{
        "fontWeight": "bold"
      }}>Clairsentience:</span> A
psion who chooses clairsentience is known as a seer. Seers can learn
precognitive powers to aid their comrades in combat, as well as powers
that permit them to gather information in many different ways.</p>
    <a id="metacreativity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Metacreativity:</span> A
psion specializing in metacreativity is known as a shaper. This
discipline includes powers that draw ectoplasm or matter from the
Astral Plane, creating semisolid and solid items such as armor,
weapons, or animated constructs to do battle at the shaper&#8217;s command.</p>
    <a id="psychokinesis"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychokinesis:</span> Psions
who specialize in psychokinesis are known as kineticists. They are the
masters of powers that manipulate and transform matter and energy.
Kineticists can attack with devastating blasts of energy.</p>
    <a id="psychometabolism"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychometabolism:</span> A
psion who specializes in psychometabolism is known as an egoist. This
discipline consists of powers that alter the psion&#8217;s psychobiology, or
that of creatures near him. An egoist can both heal and transform
himself into a fearsome fighter.</p>
    <a id="psychoportation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychoportation:</span> A
psion who relies on psychoportation powers is known as a nomad. Nomads
can wield powers that propel or displace objects in space or time.</p>
    <a id="telepathy"></a><p><span style={{
        "fontWeight": "bold"
      }}>Telepathy:</span> A psion who
chooses the discipline of telepathy is known as a telepath. He is the
master of powers that allow mental contact and control of other
sentient creatures. A telepath can deceive or destroy the minds of his
enemies with ease.
    </p>
    <h5><a href="http://www.wizards.com/dnd/images/xph_gallery/80502.jpg" style={{
        "fontWeight": "bold"
      }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><a id="psicrystals"></a>PSICRYSTALS</h5>
A psicrystal is a fragment of a psionic character&#8217;s
personality, brought into physical form and a semblance of life (via
the <a href="psionicFeats.html#psicrystal-affinity" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicrystal Affinity</a> feat). A
psicrystal appears as a crystalline
construct about the size of a human hand.
    <p>Because it is an extension of its creator&#8217;s personality, a
character&#8217;s psicrystal is in some ways a part of him. That&#8217;s why, for
example, a psionic character can manifest a personal range power on his
psicrystal even though normally he can manifest such a power only on
himself.</p>
    <p>A psicrystal is treated as a construct for the purposes of all
effects that depend on its type.</p>
    <p>A psicrystal grants special abilities to its owner, as shown
on the Psicrystal Special Abilities table below. In addition, a
psicrystal has a personality (being a fragment of the owner&#8217;s
personality), which gives its owner a bonus on certain types of checks
or saving throws, as given on the Psicrystal Personalities table below.
These special abilities and bonuses apply only when the owner and the
psicrystal are within 1 mile of each other.</p>
    <p>Psicrystal abilities are based on the owner&#8217;s levels in
psionic classes. Levels from other classes do not count toward the
owner&#8217;s level for purposes of psicrystal abilities.</p>
    <p>A psicrystal can speak one language of its owner&#8217;s choice (so
long as it is a language the owner knows). A psicrystal can understand
all other languages known by its owner, but cannot speak them. This is
a supernatural ability.</p>
    <a id="psicrystal-basics"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psicrystal Basics:</span> Use
the statistics for a <a style={{
        "color": "#579eb6"
      }} href="psionicMonsters.html#psicrystal">psicrystal</a>, but make the following changes.</p>
    <a id="psicrystal-saving-throws"></a><p><span style={{
        "fontStyle": "italic"
      }}>Saving Throws:</span> A
psicrystal uses its owner&#8217;s base saving throw bonuses and ability
modifiers on saves, though it doesn&#8217;t enjoy any other bonuses its owner
might have (from magic items or feats, for example).</p>
    <a id="psicrystal-abilities"></a><p><span style={{
        "fontStyle": "italic"
      }}>Abilities:</span> When its
self-propulsion ability is not activated, a psicrystal has no Strength
score and no Dexterity score. </p>
    <a id="psicrystal-skill"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skills:</span> A psicrystal
has the same skill ranks as its owner, except that it has a minimum of
4 ranks each in <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>,
      <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a>, and <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>.
(Even if its
owner has no ranks in these skills, a psicrystal has 4 ranks in each.)
A psicrystal uses its own ability modifiers on skill checks.
    </p>
    <a id="table-psicrystal-special-abilities"></a><span style={{
      "fontWeight": "bold"
    }}>Psicrystal Special Abilities</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Owner Level</th>
          <th style={{
            "width": "100px"
          }}>Natural
Armor Adj.</th>
          <th style={{
            "width": "50px"
          }}>Int Adj.
          </th>
          <th style={{
            "width": "350px"
          }}>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st&#8211;2nd</td>
          <td>+0</td>
          <td>+0
          </td>
          <td>Alertness, improved evasion, personality,
self-propulsion, share powers, sighted, telepathic link
          </td>
        </tr>
        <tr>
          <td>3rd&#8211;4th</td>
          <td>+1</td>
          <td>+1
          </td>
          <td>Deliver touch powers
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th&#8211;6th</td>
          <td>+2</td>
          <td>+2
          </td>
          <td>Telepathic speech
          </td>
        </tr>
        <tr>
          <td>7th&#8211;8th</td>
          <td>+3</td>
          <td>+3
          </td>
          <td>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th&#8211;10th</td>
          <td>+4</td>
          <td>+4
          </td>
          <td>Flight
          </td>
        </tr>
        <tr>
          <td>11th&#8211;12th</td>
          <td>+5</td>
          <td>+5
          </td>
          <td>Power resistance
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th&#8211;14th</td>
          <td>+6</td>
          <td>+6
          </td>
          <td>Sight link
          </td>
        </tr>
        <tr>
          <td>15th&#8211;16th</td>
          <td>+7</td>
          <td>+7
          </td>
          <td>Channel power
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th&#8211;18th</td>
          <td>+8</td>
          <td>+8
          </td>
          <td>-
          </td>
        </tr>
        <tr>
          <td className="last-row">19th&#8211;20th</td>
          <td className="last-row">+9</td>
          <td className="last-row">+9
          </td>
          <td className="last-row">-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psicrystal-ability-descriptions"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psicrystal Ability
Descriptions:</span> All psicrystals have special abilities (or impart
abilities to their owners) depending on the level of the owner, as
shown on the table above. The abilities on the table are cumulative.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Natural Armor Adj. (Ex):</span>
This number noted here is an improvement to the psicrystal&#8217;s natural
armor bonus (normally 0). It represents a psicrystal&#8217;s preternatural
durability.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Intelligence Adj. (Ex):</span>
Add this value to the psicrystal&#8217;s Intelligence score. Psicrystals are
as smart as people (though not necessarily as smart as smart people).</p>
    <a id="psicrystal-alertness"></a><p><span style={{
        "fontStyle": "italic"
      }}>Alertness (Ex):</span> The
presence of a psicrystal sharpens its master&#8217;s senses. While a
psicrystal is within arm&#8217;s reach (adjacent to or in the same square as
its owner), its owner gains the <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a> feat.</p>
    <a id="psicrystal-improved-evasion"></a><p><span style={{
        "fontStyle": "italic"
      }}>Improved Evasion (Ex):</span>
If a psicrystal is subjected to an attack that normally allows a Reflex
saving throw for half damage, it takes no damage if it makes a
successful saving throw and half damage even if the saving throw fails.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Personality (Ex):</span>
Every psicrystal has a personality. See Psicrystal Personality, below.</p>
    <a id="psicrystal-self-propulsion"></a><p><span style={{
        "fontStyle": "italic"
      }}>Self-Propulsion (Su):</span>
As a standard action, its owner can will a psicrystal to form spidery,
ectoplasmic legs that grant the psicrystal a land speed of 30 feet and
a climb speed of 20 feet. The legs fade into nothingness after one day
(or sooner, if the owner desires).</p>
    <a id="psicrystal-share-powers"></a><p><span style={{
        "fontStyle": "italic"
      }}>Share Powers (Su):</span> At
the owner&#8217;s option, he can have any power (but not any psi-like
ability) he manifests on himself also affect his psicrystal. The
psicrystal must be within 5 feet of him at the time of the
manifestation to receive the benefit. If the power has a duration other
than instantaneous, it stops affecting the psicrystal if it moves
farther than 5 feet away, and will not affect the psicrystal again,
even if it returns to its owner before the duration expires.
Additionally, the owner can manifest a power with a target of &#8220;You&#8221; on
his psicrystal (as a touch range power) instead of on himself. The
owner and psicrystal cannot share powers if the powers normally do not
affect creatures of the psicrystal&#8217;s type (construct).</p>
    <a id="psicrystal-sighted"></a><p><span style={{
        "fontStyle": "italic"
      }}>Sighted (Ex):</span>
Although it has no physical sensory organs, a psicrystal can
telepathically sense its environment as well as a creature with normal
vision and hearing. Darkness (even supernatural darkness) is
irrelevant, as are areas of supernatural silence, though a psicrystal
still can&#8217;t discern invisible or ethereal beings. A psicrystal&#8217;s
sighted range is 40 feet.</p>
    <a id="psicrystal-telepathic-link"></a><p><span style={{
        "fontStyle": "italic"
      }}>Telepathic Link (Su):</span>
The owner has a telepathic link with his psicrystal out to a distance
of up to 1 mile. The owner cannot see through the psicrystal&#8217;s senses,
but the two of them can communicate telepathically as if the psicrystal
were the target of a <a href="psionicPowersGtoP.html#mindlink" style={{
        "color": "rgb(87, 158, 182)"
      }}>mindlink</a> power manifested by the
owner. For
instance, a psicrystal placed in a distant room could relay the
activities occurring in that room.
Because of the telepathic link between a psicrystal and its owner, the
owner has the same connection to an item or place that the psicrystal
does. For instance, if his psicrystal has seen a room, the owner can
teleport into that room as if he has seen it too.</p>
    <a id="psicrystal-deliver-touch-powers"></a><p><span style={{
        "fontStyle": "italic"
      }}>Deliver Touch Powers (Su):</span>
If the owner is 3rd level or higher, his psicrystal can deliver touch
powers for him. If the owner and psicrystal are in contact at the time
the owner manifests a touch power, he can designate his psicrystal as
the &#8220;toucher.&#8221; The psicrystal can then deliver the touch power just as
the owner could. As usual, if the owner manifests another power before
the touch is delivered, the touch power dissipates.</p>
    <a id="psicrystal-telepathic-speech"></a><p><span style={{
        "fontStyle": "italic"
      }}>Telepathic Speech (Ex):</span>
If the owner is 5th level or higher, the psicrystal can communicate
telepathically with any creature that has a language and is within 30
feet of the psicrystal, while the psicrystal is also within 1 mile of
the owner.</p>
    <a id="psicrystal-flight"></a><p><span style={{
        "fontStyle": "italic"
      }}>Flight (Su):</span> If the
owner is 9th level or higher, he can, as a standard action, will his
psicrystal to fly at a speed of 50 feet (poor). The psicrystal drifts
gently to the ground after one day (or sooner, if the owner desires).</p>
    <a id="psicrystal-power-resistance"></a><p><span style={{
        "fontStyle": "italic"
      }}>Power Resistance (Ex):</span>
If the owner is 11th level or higher, the psicrystal gains power
resistance equal to the owner&#8217;s level + 5. To affect the psicrystal
with a power, another manifester must get a result on a manifester
level check that equals or exceeds the psicrystal&#8217;s power resistance.</p>
    <a id="psicrystal-sight-link"></a><p><span style={{
        "fontStyle": "italic"
      }}>Sight Link (Sp):</span> If
the owner is 13th level or higher, the character can remote view the
psicrystal (as if manifesting the remote view power) once per day. </p>
    <a id="psicrystal-channel-power"></a><p><span style={{
        "fontStyle": "italic"
      }}>Channel Power (Sp):</span>
If the owner is 15th level or higher, he can manifest powers through
the psicrystal to a distance of up to 1 mile. The psicrystal is treated
as the power&#8217;s originator, and all ranges are calculated from its
location.
When channeling a power through his psicrystal, the owner manifests the
power by paying its power point cost. He is still subject to attacks of
opportunity and other hazards of manifesting a power, if applicable
(for instance, he becomes visible when manifesting an offensive power
if <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, as does the
psicrystal).</p>
    <p>
      <a id="psicrystal-personality"></a><span style={{
        "fontWeight": "bold"
      }}>Psicrystal Personality (Ex):</span>
Each psicrystal has a distinct personality, chosen by its owner at the
time of its creation from among those given on the following table. At
1st level, its owner typically gets a feel for a psicrystal&#8217;s
personality only through occasional impulses, but as the owner
increases in level the psicrystal&#8217;s personality becomes more
pronounced. At higher levels, it is not uncommon for a psicrystal to
constantly ply its owner with observations and advice, often severely
slanted toward the psicrystal&#8217;s particular worldview. The owner always
sees a bit of himself in his psicrystal, even if magnified and
therefore distorted.
    </p>
    <a id="tavle-psicrystal-personalities"></a><span style={{
      "fontWeight": "bold"
    }}>Psicrystal Personalities</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>Personality</th>
          <th>Benefit to Owner
          </th>
        </tr>
        <tr className="odd-row">
          <td>Artiste
          </td>
          <td>+3 bonus on <a href="skillsAll.html#craft" style={{
              "color": "rgb(87, 158, 182)"
            }}>Craft</a> checks</td>
        </tr>
        <tr>
          <td>Bully</td>
          <td>+3 bonus on <a href="skillsAll.html#intimidate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Intimidate</a> checks</td>
        </tr>
        <tr className="odd-row">
          <td>Coward</td>
          <td>+3 bonus on <a href="skillsAll.html#hide" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hide</a> checks</td>
        </tr>
        <tr>
          <td>Friendly</td>
          <td>+3 bonus on <a href="skillsAll.html#diplomacy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Diplomacy</a> checks</td>
        </tr>
        <tr className="odd-row">
          <td>Hero</td>
          <td>+2 bonus on Fortitude saves</td>
        </tr>
        <tr>
          <td>Liar</td>
          <td>+3 bonus on <a href="skillsAll.html#bluff" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bluff</a> checks</td>
        </tr>
        <tr className="odd-row">
          <td>Meticulous</td>
          <td>+3 bonus on <a href="skillsAll.html#search" style={{
              "color": "rgb(87, 158, 182)"
            }}>Search</a> checks</td>
        </tr>
        <tr>
          <td>Nimble</td>
          <td>+2 bonus on Initiative checks</td>
        </tr>
        <tr className="odd-row">
          <td>Observant</td>
          <td>+3 bonus on <a href="skillsAll.html#spot" style={{
              "color": "rgb(87, 158, 182)"
            }}>Spot</a> checks</td>
        </tr>
        <tr>
          <td className="last-row">Poised</td>
          <td className="last-row">+3 bonus on <a href="skillsAll.html#balance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Balance</a>
checks</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Resolved</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+2
bonus on Will saves</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Sage</td>
          <td style={{
            "verticalAlign": "top"
          }}>+3 bonus on checks
involving any one <a href="skillsAll.html#knowledge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Knowledge</a> skill owner already
knows; once chosen,
this does not vary</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Single-minded</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+3
bonus on <a href="psionicSkills.html#concentration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concentration</a> checks</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Sneaky</td>
          <td style={{
            "verticalAlign": "top"
          }}>+3 bonus on <a href="skillsAll.html#move-silently" style={{
              "color": "rgb(87, 158, 182)"
            }}>Move
Silently</a>
checks</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Sympathetic</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+3
bonus on <a href="skillsAll.html#sense-motive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Motive</a> checks</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      